import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { TwistLockApi } from './TwistLockApi';

export class TwistLockClient implements TwistLockApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  public async getContainerRadarDetails(
    containerNamespace: string,
    containerName: string,
  ): Promise<any> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('twistlock')}`;
    const twistlockApiUrl = `${baseUrl}/radar/details?namespace=${containerNamespace}&container=${containerName}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(twistlockApiUrl, {
      headers: {
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
    });

    return await response.json();
  }

  public async getContainerRadarOverview(
    containerNamespace: string,
    containerName: string,
  ): Promise<any> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('twistlock')}`;
    const twistlockApiUrl = `${baseUrl}/radar/overview?namespace=${containerNamespace}&container=${containerName}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(twistlockApiUrl, {
      headers: {
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
    });

    return await response.json();
  }
}
