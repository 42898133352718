import { createPermission } from '@backstage/plugin-permission-common';

export const createGdspKubernetesReadPermission = createPermission({
  name: 'gdsp.kubernetes',
  attributes: {},
});

export const createGdspKubernetesPermission = [
  createGdspKubernetesReadPermission
];
