import { createPermission } from '@backstage/plugin-permission-common';

export const unprocessedReadPermission = createPermission({
  name: 'gdsp.unprocessed.read',
  attributes: {},  
});

export const unprocessedPermission = [
  unprocessedReadPermission
];
