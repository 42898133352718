import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

export class GDSPRadarClient implements TechRadarApi {
  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    return {
      quadrants: [
        { id: 'infrastructure', name: 'Infrastructure' },
        { id: 'frameworks', name: 'Frameworks/Tools' },
        { id: 'languages', name: 'Languages' },
        { id: 'process', name: 'Process' },
      ],
      rings: [
        { id: 'adopt', name: 'ADOPT', color: '#93c47d' },
        { id: 'trial', name: 'TRIAL', color: '#93d2c2' },
        { id: 'assess', name: 'ASSESS', color: '#fbdb84' },
        { id: 'hold', name: 'HOLD', color: '#efafa9' },
      ],
      entries: [
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'typescript',
          id: 'typescript',
          title: 'TypeScript',
          quadrant: 'languages',
          description: 'TypeScript',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'java',
          id: 'java',
          title: 'Java',
          quadrant: 'languages',
          description: 'Java',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'dotnet',
          id: 'dotnet',
          title: 'DotNet',
          quadrant: 'languages',
          description: 'DotNet',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'python',
          id: 'python',
          title: 'Python',
          quadrant: 'languages',
          description: 'Python',
        },
        // Framework
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: '',
            },
          ],
          url: 'https://mermaid.live/',
          key: 'mermaid',
          id: 'mermaid',
          title: 'Mermaid',
          quadrant: 'frameworks',
          description: 'Mermaid Diagram as a Code',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://polymer-library.polymer-project.org/',
          key: 'polymer',
          id: 'polymer',
          title: 'Polymer',
          quadrant: 'frameworks',
          description: 'Polymer ',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'assess',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://lit.dev/',
          key: 'lit',
          id: 'lit',
          title: 'Lit',
          quadrant: 'frameworks',
          description: 'Lit',
        },
        // Process
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'safe',
          id: 'safe',
          title: 'SAFe',
          quadrant: 'process',
          description: 'Agile SAFe',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'blue-green',
          id: 'blue-green',
          title: 'Blue-Green Deployment',
          quadrant: 'process',
          description: 'Blue-Green Deployment',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'rolling-deployment',
          id: 'rolling-deployment',
          title: 'Rolling Deployment',
          quadrant: 'process',
          description: 'Rolling Deployment',
        },
        // Infrastructure
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.ApiManagement%2Fservice',
          key: 'apim',
          id: 'apim',
          title: 'APIM',
          quadrant: 'infrastructure',
          description: 'Azure API-Management',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/microsoft.cdn%2Fprofiles',
          key: 'frontdoor-and-cdn',
          id: 'frontdoor-and-cdn',
          title: 'Front Door and CDN',
          quadrant: 'infrastructure',
          description: 'Azure Front Door and CDN',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Web%2Fsites',
          key: 'appservices',
          id: 'appservices',
          title: 'App Services',
          quadrant: 'infrastructure',
          description: 'Azure App Services',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Web%2FserverFarms',
          key: 'appservice-plans',
          id: 'appservice-plans',
          title: 'App Service Plans',
          quadrant: 'infrastructure',
          description: 'Azure App Service Plan',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.SignalRService%2FSignalR',
          key: 'signalq',
          id: 'signalq',
          title: 'SignalR',
          quadrant: 'infrastructure',
          description: 'Azure SignalR',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.ContainerRegistry%2Fregistries',
          key: 'container-registries',
          id: 'container-registries',
          title: 'Container registries',
          quadrant: 'infrastructure',
          description: 'Azure Container Registries',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Web%2Fsites/kind/functionapp',
          key: 'functions-app',
          id: 'functions-app',
          title: 'Function App',
          quadrant: 'infrastructure',
          description: 'Azure Function App',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Storage%2FStorageAccounts',
          key: 'storage-accounts',
          id: 'storage-accounts',
          title: 'Storage Accounts',
          quadrant: 'infrastructure',
          description: 'Azure Storage Accounts',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Compute%2FVirtualMachines',
          key: 'virtual-machines',
          id: 'virtual-machines',
          title: 'Virtual Machines',
          quadrant: 'infrastructure',
          description: 'Azure Virtual Machines',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'assess',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.ContainerService%2FmanagedClusters',
          key: 'kubernetes-services',
          id: 'kubernetes-services',
          title: 'Azure Kubernetes Services',
          quadrant: 'infrastructure',
          description: 'Azure Kubernetes Services',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Network%2FdnsZones',
          key: 'dns-zones',
          id: 'dns-zones',
          title: 'DNS zones',
          quadrant: 'infrastructure',
          description: 'Azure DNS zones',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.KeyVault%2Fvaults',
          key: 'key-vaults',
          id: 'key-vaults',
          title: 'Key vaults',
          quadrant: 'infrastructure',
          description: 'Azure Key vaults',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Logic%2Fworkflows',
          key: 'logic-apps',
          id: 'logic-apps',
          title: 'Logic apps',
          quadrant: 'infrastructure',
          description: 'Azure Logic apps',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'postgresql',
          id: 'postgresql',
          title: 'PostgreSQL',
          quadrant: 'infrastructure',
          description: 'Azure PostgreSQL',
        },

        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'aro',
          id: 'aro',
          title: 'ARO',
          quadrant: 'infrastructure',
          description: 'Azuer RedHat OpenShift',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'mongodb',
          id: 'mongodb',
          title: 'Mongo DB',
          quadrant: 'infrastructure',
          description: 'Mongo DB - ARO',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'on-perm-vm',
          id: 'on-perm-vm',
          title: 'On-Perm VMs',
          quadrant: 'infrastructure',
          description: 'On-Perm VMs',
        },
        // Tools
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://blackduck-vt.got.volvo.net/',
          key: 'blackduck',
          id: 'blackduck',
          title: 'BlackDuck',
          quadrant: 'frameworks',
          description: 'Blackduck',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: 'https://sonarqube.71199p01.euw-hub02.azure.volvo.net/',
          key: 'sonarqube',
          id: 'sonarqube',
          title: 'SonarQube',
          quadrant: 'frameworks',
          description: 'SonarQube',
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: 'adopt',
              date: new Date('2023-01-01'),
              description: 'Optional',
            },
          ],
          url: '#',
          key: 'backstage',
          id: 'backstage',
          title: 'Backstage',
          quadrant: 'frameworks',
          description: 'Backstage - Developer Portal',
        },
      ],
    };
  }
}
