import { createApiRef } from '@backstage/core-plugin-api';

/** @public */
export const twistlockApiRef = createApiRef<TwistLockApi>({
  id: 'plugin.twistlock.service',
});

/** @public */
export interface TwistLockApi {
  getContainerRadarDetails(containerNamespace: string, containerName: string): Promise<any>;
  getContainerRadarOverview(containerNamespace: string, containerName: string): Promise<any>;
};