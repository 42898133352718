import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
  identityApiRef,
  createRoutableExtension
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

import { twistlockApiRef, TwistLockClient } from './api';

export const volvoTwistlockPlugin = createPlugin({
  id: 'volvo-twistlock',
  apis: [
    createApiFactory({
      api: twistlockApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new TwistLockClient({
          discoveryApi,
          identityApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const EntityVolvoTwistlockPage = volvoTwistlockPlugin.provide(
  createRoutableExtension({
    name: 'EntityVolvoTwistlockPage',
    component: () =>
      import('./components/TwistLockPage').then(m => m.TwistLockPage),
    mountPoint: rootRouteRef,
  }),
);


/** @public */
export const EntityVolvoTwistlockCard = volvoTwistlockPlugin.provide(
  createRoutableExtension({
    name: 'EntityVolvoTwistlockCard',
    component: () =>            
      import('./components/TwistLockCard').then(m => m.TwistLockCard),
    mountPoint: rootRouteRef,
  }),
);
