import React from 'react';
import {
  Content,
  Header,
  Page,
  EmptyState,
} from '@backstage/core-components';
import { ForbiddenContent } from './ForbiddenContent';

export const ForbiddenPage = () => {
  return (
    <Page themeId="default">
      <Header title="Forbidden" />
      <Content> 
        <ForbiddenContent />
      </Content>
    </Page>
  );
};
