import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 70,    
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();
  const fillColor = "#ffffff"; //useTheme().palette.type === "dark" ? "#ffffff": "#000000"

  // return <img src={VolvoLogo} className={classes.svg}/>;
  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 338.75 338.72"
    >
      <path d="M0 0h338.75v338.75H0z" fill="none"></path>
      <path
        fill={fillColor}
        d="M158.37 177.93h27.41v-9.15h-3.01c0 3.5-2.56 6.08-6.47 6.08h-4.22V161.2h4.55v-3.03h-18.26v3.03h4.6l.01 13.66h-4.61v3.07zM198.96 177.94l10.66.01 9.05-16.74h4.54v-3.03h-13.65v3.03h3.95l-6.6 12.19-6.68-12.19h4.79v-3.03h-19.8l-.01 3.03h4.54zM103.79 158.18h-19.8l-.01 3.03h4.54l9.21 16.73 10.66.01 9.05-16.74h4.54v-3.03h-13.65v3.03h3.94l-6.59 12.19L99 161.21h4.79zM124.63 168.08c0 5.82 4.69 10.45 14.09 10.45 9.4 0 14.04-4.63 14.04-10.45 0-5.74-4.92-10.32-14.1-10.32-9.16 0-14.03 4.58-14.03 10.32zm18.93.05c-.06 3.59-1.55 7.33-4.99 7.27-3.35-.06-4.82-3.76-4.76-7.44.07-3.81 1.89-7.13 5.03-7.08 3.14.05 4.79 3.44 4.72 7.25zM225.83 168.08c0 5.82 4.69 10.45 14.09 10.45 9.4 0 14.04-4.63 14.04-10.45 0-5.74-4.92-10.32-14.09-10.32s-14.04 4.58-14.04 10.32zm14.21-7.2c3.13.06 4.79 3.44 4.72 7.25-.07 3.59-1.55 7.33-4.99 7.27-3.35-.06-4.82-3.76-4.76-7.44.07-3.82 1.89-7.14 5.03-7.08z"
      ></path>
      <path
        fill={fillColor}
        d="m262.3 105.22-11.47 11.47c9.83 15.17 15.55 33.26 15.55 52.69 0 53.57-43.43 97-97 97s-97-43.43-97-97 43.43-97 97-97c19.47 0 37.6 5.74 52.8 15.62l11.47-11.47c-18.24-12.65-40.39-20.07-64.27-20.07-62.36 0-112.92 50.55-112.92 112.92S107.01 282.3 169.38 282.3 282.3 231.75 282.3 169.38c-.01-23.84-7.4-45.94-20-64.16z"
      ></path>
      <path
        fill={fillColor}
        d="M226.18 60.9v3.17c6.85 3.71 13.29 8.09 19.26 13.01l-16.09 16.09a96.807 96.807 0 0 1 16.32 16.3l16.07-16.07c4.93 5.98 9.31 12.43 13.02 19.18h3.09V60.9h-51.67z"
      ></path>
    </svg>
  );
};

export default LogoIcon;
