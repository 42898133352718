import React from 'react';
import {
  Content,  
  EmptyState,
} from '@backstage/core-components';

export const ForbiddenContent = () => {
  return (
      <Content>
        <EmptyState
          missing="content"
          title="403 - Forbidden"
          description="You are not permitted to view this"
        />
      </Content>
  );
};
