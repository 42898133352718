import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 190,
    height: 'auto',
    marginLeft: 15,
  },
});
const LogoFull = () => {
  const classes = useStyles();
  const fillColor = '#ffffff';
  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 80"
    >
      <g>
        <polygon
          fill={fillColor}
          points="16.11,12.38 48.78,71.75 86.62,71.77 118.74,12.37 134.84,12.38 134.84,1.61 86.41,1.61 86.41,12.37 100.4,12.37 77.01,55.62 53.31,12.38 70.3,12.37 70.3,1.61 0.04,1.61 0.03,12.38 "
        />
        <path
          fill={fillColor}
          d="M432.43,12.35l-0.01-10.74l64.79,0.01v10.73h-16.14v48.46h14.96c13.86,0,22.95-9.16,22.95-21.58h10.69v32.45 l-97.25,0.01V60.83h16.33l-0.02-48.48H432.43z"
        />
        <g>
          <path
            fill={fillColor}
            d="M275.33,0.13c-32.53,0-49.81,16.23-49.81,36.59c0,20.65,16.64,37.11,50,37.11 c33.35,0,49.81-16.46,49.81-37.11C325.33,16.36,307.87,0.13,275.33,0.13z M274.97,62.69c-11.88-0.21-17.1-13.34-16.88-26.39 c0.24-13.51,6.7-25.29,17.83-25.1c11.13,0.2,17.01,12.2,16.77,25.71C292.46,49.65,287.2,62.91,274.97,62.69z"
          />
        </g>
        <polygon
          fill={fillColor}
          points="640.19,12.38 672.87,71.75 710.71,71.77 742.82,12.37 758.92,12.38 758.92,1.61 710.5,1.61 710.5,12.37 724.48,12.37 701.1,55.62 677.39,12.38 694.39,12.37 694.39,1.61 624.13,1.61 624.11,12.38 "
        />
        <g>
          <path
            fill={fillColor}
            d="M899.43,0.13c-32.53,0-49.81,16.23-49.81,36.59c0,20.65,16.64,37.11,50,37.11 c33.35,0,49.81-16.46,49.81-37.11C949.43,16.36,931.97,0.13,899.43,0.13z M899.07,62.69c-11.88-0.21-17.1-13.34-16.88-26.39 c0.24-13.51,6.7-25.29,17.83-25.1c11.13,0.2,17,12.2,16.76,25.71C916.57,49.65,911.31,62.91,899.07,62.69z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
