import { createPermission } from '@backstage/plugin-permission-common';

export const createWorkflowReadPermission = createPermission({
  name: 'create-workflow',
  attributes: {},
});

export const createWorkflowPermission = [
    createWorkflowReadPermission
];
