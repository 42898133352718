import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
  DefaultTechDocsHome,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue, TextSize, LightBox } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { SignInPage } from '@backstage/core-components';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { Entity } from '@backstage/catalog-model';

import { HomePage } from './components/home/HomePage';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { createWorkflowReadPermission, unprocessedReadPermission } from '@volvo/backstage-plugin-permissions-common';
import { ForbiddenPage } from './components/lib/ForbiddenPage';
import {
  AzurePullRequestsPage,
  FilterType,
  PullRequestColumnConfig,
} from '@backstage/plugin-azure-devops';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';


const PR_FILTER_COLUMN_CONFIGS: PullRequestColumnConfig[] = [
  {
    title: 'Created by me',
    filters: [{ type: FilterType.CreatedByCurrentUser }],
    simplified: false,
  },
  {
    title: 'Assigned to me',
    filters: [{ type: FilterType.AssignedToCurrentUser }],
    simplified: true,
  },
  {
    title: 'Created by my Teams',
    filters: [{ type: FilterType.CreatedByCurrentUsersTeams }],
    simplified: true,
  },
  {
    title: 'Assigned to my Team',
    filters: [{ type: FilterType.AssignedToCurrentUsersTeams }],
    simplified: true,
  },
];

const app = createApp({
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'azure-auth-provider',
          title: 'GDSP IDP',
          message: 'Sign in using Azure AD',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    {/* <Route path="/" element={<Navigate to="catalog" />} /> */}
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} >
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <TextSize />
        <LightBox />
        <Mermaid
          darkConfig={{ theme: 'dark' }}
          lightConfig={{ theme: 'corporate' }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={

      <RequirePermission        
        permission={createWorkflowReadPermission}
        errorPage={<ForbiddenPage />}
      >
        <ScaffolderPage
          contextMenu={{ editor: true, actions: true }}
          // groups={[
          //   {
          //     title: 'Backend Service',
          //     filter: (entity: Entity) =>
          //       (entity?.metadata.tags?.includes('backend') &&
          //         entity?.metadata.tags?.includes('service')) ??
          //       false,
          //   },
          //   {
          //     title: 'Backend Library',
          //     filter: (entity: Entity) =>
          //       (entity?.metadata.tags?.includes('backend') &&
          //         entity?.metadata.tags?.includes('library')) ??
          //       false,
          //   },
          // ]}
           /></RequirePermission>} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/az-pr"
      element={
        <AzurePullRequestsPage
          projectName="DSBS-SYSTEMS"
          defaultColumnConfigs={PR_FILTER_COLUMN_CONFIGS}
          pollingInterval={100000}
        />
      }
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/catalog-unprocessed-entities"
      element={
        <RequirePermission          
          permission={unprocessedReadPermission}
          errorPage={<ForbiddenPage />}
        >
          <CatalogUnprocessedEntitiesPage />
        </RequirePermission>}
    />;
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
