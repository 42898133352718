import {
  HomePageToolkit,
  HomePageStarredEntities,
  HeaderWorldClock,
  ClockConfig,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles, Avatar, Typography } from '@material-ui/core';
import React from 'react';

import { Header } from '@backstage/core-components';
import { useUserProfile } from './useUserProfileInfo';
import { BackstageTheme } from '@backstage/theme';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';

const useStyles = makeStyles<BackstageTheme, { size: number }>(theme => ({
  avatar: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    fontSize: ({ size }) => size * 0.7,
    border: `1px solid ${theme.palette.textSubtle}`,
  },
}));

import {
  AzureLogo,
  AzureDevOpsLogo,
  ConfluenceLogo,
  OpenShiftLogo,
  MonitoringLogo,
  SharePointLogo,
  ServiceNowLogo,
  GrafanaLogo,
  KibanaLogo,
  JaegerLogo,
  SonarLogo,
  AdminLogo,
  TwistLockLogo,
  BlackDuckLogo,
} from './iconList';

export const HomePage = () => {
  const classes = useStyles({ size: 96 });
  const { profile } = useUserProfile();

  const clockConfigs: ClockConfig[] = [
    {
      label: 'UTC',
      timeZone: 'UTC',
    },
    {
      label: 'GOT',
      timeZone: 'Europe/Stockholm',
    },
    {
      label: 'IND',
      timeZone: 'Asia/Kolkata',
    },
    {
      label: 'CUR',
      timeZone: 'America/Sao_Paulo',
    },
  ];
  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const toolLink = [
    {
      url: 'https://portal.azure.com/',
      label: 'Azure',
      icon: <AzureLogo />,
    },
    {
      url: 'https://dev.azure.com/VolvoGroup-DVP/',
      label: 'Azure DevOps',
      icon: <AzureDevOpsLogo />,
    },
    {
      url: 'https://sonarqube.71199p01.euw-hub02.azure.volvo.net/',
      label: 'SonarQube',
      icon: <SonarLogo />,
    },
    {
      url: 'https://admin.gdsp.volvo.com/',
      label: 'GDSP Admin',
      icon: <AdminLogo />,
    },
    {
      url: 'https://confluence.srv.volvo.com/display/GDSP/GDSP+Home',
      label: 'Confluence',
      icon: <ConfluenceLogo />,
    },
    {
      url: 'https://volvogroup.sharepoint.com/sites/proj-diagnostic-swdl-services-architects/',
      label: 'SharePoint',
      icon: <SharePointLogo />,
    },
    {
      url: 'https://dashboard.gdsp.volvo.com/',
      label: 'Dashboard',
      icon: <MonitoringLogo />,
    },
    {
      url: 'https://volvoitsm.service-now.com/sp',
      label: 'ServiceNow',
      icon: <ServiceNowLogo />,
    },
    {
      url: 'https://confluence.srv.volvo.com/display/GDSP/DSBS+Developer+onboarding',
      label: 'Developer OnBoarding',
      icon: <ConfluenceLogo />,
    },
    {
      url: 'https://confluence.srv.volvo.com/display/GDSP/Conceptual+View',
      label: 'GDSP Architecture',
      icon: <ConfluenceLogo />,
    },
    {
      url: 'https://confluence.srv.volvo.com/pages/viewpage.action?pageId=221184036',
      label: 'BCT Outage',
      icon: <ConfluenceLogo />,
    },
  ];

  const toolProdLink = [
    {
      url: 'https://console-openshift-console.apps.aro-echo.euw-hub02.azure.volvo.net/k8s/cluster/projects/',
      label: 'ARO',
      icon: <OpenShiftLogo />,
    },
    {
      url: 'https://twistlock-console-twistlock.apps.aro-echo.euw-hub02.azure.volvo.net',
      label: 'TwistLock',
      icon: <TwistLockLogo />,
    },
    {
      url: 'https://grafana-gdsp-telemetry-prod.apps.aro-echo.euw-hub02.azure.volvo.net/?orgId=1',
      label: 'Telemetry',
      icon: <GrafanaLogo />,
    },
    {
      url: 'https://kibana-gdsp-telemetry-prod.apps.aro-echo.euw-hub02.azure.volvo.net/app/home',
      label: 'Logging',
      icon: <KibanaLogo />,
    },
    {
      url: 'https://jaeger-query-gdsp-telemetry-prod.apps.aro-echo.euw-hub02.azure.volvo.net/search',
      label: 'Tracing',
      icon: <JaegerLogo />,
    },
    {
      url: 'https://blackduck-vt.got.volvo.net/',
      label: 'BlackDuck',
      icon: <BlackDuckLogo />,
    },
  ];

  const toolQALink = [
    {
      url: 'https://console-openshift-console.apps.aro-delta.euw-hub02.azure.volvo.net/k8s/cluster/projects',
      label: 'ARO',
      icon: <OpenShiftLogo />,
    },
    {
      url: 'https://twistlock-console-twistlock.apps.aro-delta.euw-hub02.azure.volvo.net',
      label: 'TwistLock',
      icon: <TwistLockLogo />,
    },
    {
      url: 'https://grafana-gdsp-telemetry-qa.apps.aro-delta.euw-hub02.azure.volvo.net/?orgId=1',
      label: 'Telemetry',
      icon: <GrafanaLogo />,
    },
    {
      url: 'https://kibana-gdsp-telemetry-qa.apps.aro-delta.euw-hub02.azure.volvo.net/app/home',
      label: 'Logging',
      icon: <KibanaLogo />,
    },
    {
      url: 'https://jaeger-query-gdsp-telemetry-qa.apps.aro-delta.euw-hub02.azure.volvo.net/search',
      label: 'Tracing',
      icon: <JaegerLogo />,
    },
  ];

  return (
    <SearchContextProvider>
      <Header title="GDSP Internal Developer Platform" pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      <Page themeId="home">        
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item md={12}>
              <NewAnnouncementBanner />
            </Grid>
            <Grid container item xs={12}>
              <Grid>
                <Avatar
                  src={profile.picture}
                  className={classes.avatar}
                  alt="Profile picture"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid direction="column" spacing={2}>
                  <Grid>
                    <Typography variant="subtitle1" gutterBottom>
                      Welcome {profile.displayName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {profile.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit tools={toolLink} title="Quick Links" />
              </Grid>

              <Grid item md={6}>
                <AnnouncementsCard max={3} />
              </Grid>

              <Grid item xs={12} md={3}>
                <HomePageToolkit tools={toolProdLink} title="Tools Prod" />
              </Grid>
              <Grid item xs={12} md={3}>
                <HomePageToolkit tools={toolQALink} title="Tools QA" />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
